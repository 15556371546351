import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayoutComponent/DashboardLayout';
import { tryGetToken } from './services/DashLayoutService';
import { withRouter } from 'react-router-dom';
import Status from './components/StatusComponent/Status';
import 'antd/dist/antd.css';
import decode from 'jwt-decode';

function App({location, history}) {

  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() =>{
    const token = tryGetToken(location, history);
    setDecodedToken(decode(token));
  }, [location, history]);



  return (
    <div className="app" id="app-root">
      <Switch>
        <Route path="/unauthorized">
          Unauthorized
        </Route>
        <Route path="/status">
          <Status />
        </Route>
        <Route path="/">
          <DashboardLayout decodedToken={decodedToken} />
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);
