// TODO: should be taken from BE

import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/person_by_race_with_peer",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "By Subject Race",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Name",
        dataIndex: "officer_full_name",
      },
      {
        title: "Title",
        dataIndex: "title",
      },
      {
        title: "Badge Number",
        dataIndex: "badge_number",
      },
      {
        title: "American Indian/Alaskan Native",
        children: [
          {
            title: "Pct",
            dataIndex: ["American Indian/Alaskan Native", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["American Indian/Alaskan Native", "peer_summary"],
          },
        ],
      },
      {
        title: "Black",
        children: [
          {
            title: "Pct",
            dataIndex: ["Black", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Black", "peer_summary"],
          },
        ],
      },
      {
        title: "White",
        children: [
          {
            title: "Pct",
            dataIndex: ["White", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["White", "peer_summary"],
          },
        ],
      },
      {
        title: "Hispanic",
        children: [
          {
            title: "Pct",
            dataIndex: ["Hispanic", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Hispanic", "peer_summary"],
          },
        ],
      },
      {
        title: "Unknown Race",
        children: [
          {
            title: "Pct",
            dataIndex: ["Unknown Race", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Unknown Race", "peer_summary"],
          },
        ],
      },
    ],
  },
};
