import React, { useEffect, useState, useContext } from "react";
import classes from "./PeerComparisons.module.scss";
import {
  getGenderPeerDetails,
  getRacePeerDetails,
  getAgePeerDetails,
  raceColumns,
  genderColumns,
  ageColumns,
} from "../../services/PeerComparisonService";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";
import config from "../../config.json";
import TableBox from "../../components/TableBoxComponent/TableBox";

import { MV_STOP_DEMOGRAPHICS_BY_ORG_TITLE } from "../../layouts/DashboardLayoutComponent/DashboardLayout";

function PeerComperisons({ segmentParams, location }) {
  const [raceComparisons, setRaceComparisons] = useState(null);
  const [genderComparisons, setGenderComparisons] = useState(null);
  const [ageComparisons, setAgeComparisons] = useState(null);
  const [raceLoadText, setRaceLoadText] = useState(null);
  const [genderLoadText, setGenderLoadText] = useState(null);
  const [ageLoadText, setAgeLoadText] = useState(null);

  const [raceFailure, setRaceFailure] = useState(null);
  const [genderFailure, setGenderFailure] = useState(null);
  const [ageFailure, setAgeFailure] = useState(null);

  const [timeOutSet, setTimeoutSet] = useState(null);
  const [timedOut, setTimedOut] = useState(false);
  const [initialized, setIniitalized] = useState(null);

  const dashLayoutContext = useContext(DashboardLayoutContext);

  const catchFetchingError = (error) => {
    //TODO: set toast
    console.log("Error fetching data", error);
  };
  useEffect(() => {
    if (!initialized) {
      dashLayoutContext.setPageHeading(MV_STOP_DEMOGRAPHICS_BY_ORG_TITLE);
      dashLayoutContext.setBreadCrumb(<span>Motor Vehicle Stops</span>);
      setIniitalized(true);
    }
  }, [dashLayoutContext, initialized]);

  useEffect(() => {
    /******************************************
     *  This function is triggered
     *  when time out for API calls experies
     ******************************************/

    if (timedOut) {
      if (genderLoadText) {
        setGenderFailure("Failed fetching gender comparisons.");
      }
      if (raceLoadText) {
        setRaceFailure("Failed fetching race comparisons.");
      }
      if (ageLoadText) {
        setAgeFailure("Failed fetching gender comparisons.");
      }
      setAgeLoadText(null);
      setRaceLoadText(null);
      setGenderLoadText(null);
      setTimedOut(false);
    }
  }, [timedOut, genderLoadText, ageLoadText, raceLoadText]);

  useEffect(() => {
    /******************************************
     *  This function is triggered
     *  when time out for API calls experies
     ******************************************/

    if (!genderLoadText && !ageLoadText && !raceLoadText && !timeOutSet) {
      setTimeoutSet(true);
      dashLayoutContext.setFetching(true);
      setTimeout(() => {
        setTimedOut(true);
      }, config.ApiCallTimeout);
    }
  }, [
    genderLoadText,
    ageLoadText,
    raceLoadText,
    dashLayoutContext,
    timeOutSet,
  ]);

  useEffect(() => {
    if ((raceComparisons && genderComparisons && ageComparisons) || timedOut) {
      dashLayoutContext.setFetching(false);
    }
  }, [
    raceComparisons,
    genderComparisons,
    ageComparisons,
    timedOut,
    dashLayoutContext,
  ]);

  useEffect(() => {
    if (
      segmentParams.asOfDate &&
      segmentParams.timeSpanUnit &&
      segmentParams.organization
    ) {
      setGenderComparisons(null);
      setRaceComparisons(null);
      setAgeComparisons(null);
      setRaceFailure(null);
      setGenderFailure(null);
      setAgeFailure(null);

      setRaceLoadText("Fetching race comparisons ...");
      getRacePeerDetails(
        segmentParams.asOfDate,
        segmentParams.timeSpanUnit,
        segmentParams.organization
      )
        .then((peerRaceComparisonDetails) => {
          setRaceLoadText(null);
          setRaceComparisons(peerRaceComparisonDetails);
        })
        .catch(catchFetchingError);

      setGenderLoadText("Fetching gender comparisons ...");
      getGenderPeerDetails(
        segmentParams.asOfDate,
        segmentParams.timeSpanUnit,
        segmentParams.organization
      )
        .then((peerGenderComparisonDetails) => {
          setGenderLoadText(null);
          setGenderComparisons(peerGenderComparisonDetails);
        })
        .catch(catchFetchingError);

      setAgeLoadText("Fetching age comparisons ...");
      getAgePeerDetails(
        segmentParams.asOfDate,
        segmentParams.timeSpanUnit,
        segmentParams.organization
      )
        .then((peerAgeComparisonDetails) => {
          setAgeLoadText(null);
          setAgeComparisons(peerAgeComparisonDetails);
        })
        .catch(catchFetchingError);
    }
  }, [
    segmentParams.asOfDate,
    segmentParams.timeSpanUnit,
    segmentParams.organization,
  ]);

  return (
    <div className={classes.PeerBox}>
      <div className={classes.PeerContent}>
        <TableBox
          columnDefinitions={raceColumns}
          data={raceComparisons}
          loaderText={raceLoadText}
          title="Organizations Stops by Race"
          scrollWidth={1700}
          failedText={raceFailure}
        />
        <TableBox
          columnDefinitions={genderColumns}
          data={genderComparisons}
          loaderText={genderLoadText}
          title="Organizations Stops by Gender"
          failedText={genderFailure}
        />
        <TableBox
          columnDefinitions={ageColumns}
          data={ageComparisons}
          loaderText={ageLoadText}
          title="Organizations Stops by Age"
          scrollWidth={2700}
          failedText={ageFailure}
        />
      </div>
    </div>
  );
}

export default withParams(PeerComperisons);
