// TODO: should be taken from BE

import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/person_by_gender_with_peer",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "By Subject Gender",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Name",
        dataIndex: "officer_full_name",
      },
      {
        title: "Title",
        dataIndex: "title",
      },
      {
        title: "Badge Number",
        dataIndex: "badge_number",
      },
      {
        title: "Female",
        children: [
          {
            title: "Pct",
            dataIndex: ["Female", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Female", "peer_summary"],
          },
        ],
      },
      {
        title: "Male",
        children: [
          {
            title: "Pct",
            dataIndex: ["Male", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Male", "peer_summary"],
          },
        ],
      },
      {
        title: "Unknown Gender",
        children: [
          {
            title: "Pct",
            dataIndex: ["Unknown Gender", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Unknown Gender", "peer_summary"],
          },
        ],
      },
    ],
  },
};
