import React from "react";
import { withRouter } from "react-router-dom";

const buildSegementParams = (path, url) => {
  const pathSplit = path.split("/");
  const urlSplit = url.split("/");
  const segmentParams = {};
  pathSplit.forEach((p, i) => {
    if (p.length && p[0] === ":") {
      segmentParams[p.substr(1)] = urlSplit[i];
    }
  });
  return segmentParams;
};

const buildQueryParams = (location) => {
  const searchStr = location.search.replace(/^\?/, "");
  return searchStr
    .split("&")
    .filter((d) => d && d.length > 0)
    .reduce((result, kv) => {
      const [k, v] = kv.split("=");
      result[k] = v;
      return result;
    }, {});
};

export const withParams = (Component) => {
  return withRouter((props) => {
    const segmentParams = buildSegementParams(
      props.match.path,
      props.match.url
    );
    const queryParams = buildQueryParams(props.location);
    return <Component {...{ queryParams, segmentParams }} {...props} />;
  });
};
