import GridComponent from "../components/GenericComponents/GridComponent";
import TestChart from "../components/GenericComponents/TestChart";
//import GridBox from "../components/GenericComponents/GridBoxComponent";

// TODO: the constant must be shared between BE & FE
export const VISUALIZATION_TYPE_GRID = "Grid";
export const VISUALIZATION_TYPE_GRID_BOX = "GridBox";

export function getComponentByType(type) {
  switch (type) {
    case "TestChart":
      return TestChart;
    // case "GridBox":
    //   return GridBox;
    case VISUALIZATION_TYPE_GRID:
    default:
      return GridComponent;
  }
}
