import React, { useEffect, useState } from "react";
import classes from "./DashboardLayout.module.scss";
import UserCard from "../../components/UserCardComponent/UserCard";
import {
  getTimeSpanFilters,
  getOrganizationFilters,
} from "../../services/DashLayoutService";

import { getDimensionLookup } from "../../services/DimensionsService";

import Navigation from "../../components/NavigationCompnent/Navigation";
import Logo from "../../images/benchmark-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import FilterBar from "../../components/FilterBarComponent/FilterBar";
import AnalysisBar from "../../components/AnalysisBarComponent/AnalysisBar";
import Dashboard from "../../pages/DashboardPage/Dashboard";
import DashboardLayoutContext from "./DashboardLayoutContext";
import AnalysisPage from "../../pages/AnalysisPage/Analysis";
import EventPage from "../../pages/EventPage/Event";
import PeerComparisonsPage from "../../pages/PeerComparisonsPage/PeerComperisons";
import moment from "moment";
import PersonPeerPage from "../../pages/PersonPeerPage/PersonPeerPage";
import TrooperScatterPlotPage from "../../pages/ScatterPlotPage/ScatterPlotPage";
import CompareReasonByOrganization from "../../pages/CompareReasonByOrganization/CompareReasonByOrganization";
import StopReasonsPage from "../../pages/StopReasonsPage/StopReasons";
import StopOutcomePage from "../../pages/StopOutcomePage/StopOutcome";
import StopOutcomePeerPage from "../../pages/StopOutcomePeerComparisonPage/StopOutcomePeerComparison";
import PostStopInteractionPage from "../../pages/PostStopInteractionPage/PostStopInteraction";
import PostStopInteractionPeerComparisonPage from "../../pages/PostStopInteractionPeerComparisonPage/PostStopInteractionPeerComparison";

import TestPage from "../../pages/TestPage/Test";

export const MV_STOP_DEMOGRAPHICS_TITLE = "MV Stop Demographics";
export const MV_STOP_DEMOGRAPHICS_BY_ORG_TITLE =
  "MV Stop Demographics - Peer Comparison";
export const MV_STOP_DEMOGRAPHICS_BY_TROOPER_TITLE =
  "MV Stop Demographics - by Trooper";
export const MV_STOP_REASON_BY_ORGANIZATION_TITLE = "MV Stop Reasons";
export const MV_STOP_OUTCOME_BY_ORGANIZATION_TITLE = "MV Stop Outcomes";
export const MV_STOP_OUTCOME_BY_PEER_COMPARISON =
  "MV Stop Outcomes - Peer Comparison";
export const MV_STOP_COMPARE_REASON_BY_ORGANIZATION_TITLE =
  "MV Stop Reasons - Peer Comparison";
export const MV_POST_STOP_INTERACTION_BY_ORGANIZATION_TITLE =
  "MV Stop Post-Stop Interactions";
export const MV_POST_STOP_INTERACTION_BY_PEER_TITLE =
  "MV Stop Post-Stop Interactions - Peer Comparison";
export const MV_STOP_TROOPER_SCATTERPLOT_TITLE = "MV Stop Trooper ScatterPlot";

function DashboardLayout({ location, history, decodedToken }) {
  const [session, setSession] = useState(null);
  const [pageHeading, setPageHeading] = useState(null);
  const [dimensionLookups, setDimensionLookups] = useState({});
  const [analysesBarData, setAnalysesBarData] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [timeSpanUnit, setTimeSpanUnit] = useState(null);
  const [asOfDate, setAsOfDate] = useState(null);
  const [timeSpanFilters, setTimeSpanFilters] = useState(null);
  const [organizationFilters, setOrganizationFilters] = useState(null);
  const [fetching, setFetching] = useState(null);
  const [hideBack, setHideBack] = useState(null);
  const [breadCrumb, setBreadCrumb] = useState(null);

  useEffect(() => {
    if (decodedToken) {
      decodedToken.currentUser = {
        fullName: decodedToken.user_name,
        firstName: "",
        lastName: "",
        rank: {
          name: "",
        },
        agency: {
          name: decodedToken.agency.name,
        },
      };
    }
    setSession(decodedToken);
  }, [decodedToken]);

  useEffect(() => {
    getDimensionLookup()
      .then((result) => {
        setDimensionLookups(result);
      })
      .catch((err) => {
        console.log("Error during gathering dimension lookup:", err);
      });
  }, []); // No dependencies, doing it only once

  useEffect(() => {
    setPageHeading("Analytics Dashboard");
    const fetchFiltersAndChartData = async () => {
      try {
        const timeSpanFiltersData = await getTimeSpanFilters();
        const organizationFiltersData = await getOrganizationFilters();
        // const analysesData = await getAnalysisBarData();
        // TODO: Get correct data
        const mockAnalysis = [
          {
            id: 1,
            route: "/analysis/",
            name: MV_STOP_DEMOGRAPHICS_TITLE,
            children: [
              {
                id: 2,
                route: "/peer/",
                name: "By Peer",
              },
              {
                id: 3,
                route: "/person_peer/",
                name: "By Trooper",
              },
              {
                id: 10,
                route: "/stop-trooper-scatterplot/",
                name: "Trooper ScatterPlot",
              },
            ],
          },
          {
            id: 4,
            route: "/stop-reason/",
            name: MV_STOP_REASON_BY_ORGANIZATION_TITLE,
            children: [
              {
                id: 5,
                route: "/compare_reason_by_organization/",
                name: "By Peer",
              },
            ],
          },
          {
            id: 6,
            route: "/stop-outcome/",
            name: MV_STOP_OUTCOME_BY_ORGANIZATION_TITLE,
            children: [
              {
                id: 7,
                route: "/stop-outcome-peer/",
                name: "By Peer",
              },
            ],
          },
          {
            id: 8,
            route: "/post-stop-interaction/",
            name: MV_POST_STOP_INTERACTION_BY_ORGANIZATION_TITLE,
            children: [
              {
                id: 9,
                route: "/post-stop-interaction-peer/",
                name: "By Peer",
              },
            ],
          },
          // {
          //   id: 8,
          //   route: "/test/",
          //   name: 'test',
          // },
        ];
        setAnalysesBarData(mockAnalysis);
        setTimeSpanFilters(timeSpanFiltersData);
        setOrganizationFilters(organizationFiltersData);
        if (organizationFiltersData[0]) {
          setOrganization(organizationFiltersData[0].organization_id);
        }
        if (timeSpanFiltersData[0]) {
          setTimeSpanUnit(timeSpanFiltersData[0].range_unit);
        }
      } catch (error) {
        // TODO: set toast notification
        console.log("Error initializing dashboard", error);
      }
    };
    fetchFiltersAndChartData();
    setAsOfDate(moment().subtract(1, "days").format("YYYYMMDD"));
  }, []);

  const filterChanged = async (e) => {
    const current = {};
    switch (e.type) {
      case "date":
        if (e.value) {
          const date = e.value.format("YYYYMMDD");
          current.organization = organization;
          current.timeSpanUnit = timeSpanUnit;
          current.asOfDate = date;
          setAsOfDate(date);
        }
        break;
      case "unit":
        current.organization = organization;
        current.timeSpanUnit = e.value;
        current.asOfDate = asOfDate;
        setTimeSpanUnit(e.value);
        break;
      default:
        current.organization = e.value;
        current.timeSpanUnit = timeSpanUnit;
        current.asOfDate = asOfDate;
        setOrganization(e.value);
        break;
    }

    if (location.pathname.includes("event")) {
      // must crop querystring for additional params
      const split = location.pathname.split("/");
      history.push(
        `/event/${split[2]}/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (location.pathname.includes("analysis")) {
      history.push(
        `/analysis/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (
      location.pathname.includes("peer") &&
      !location.pathname.includes("person_peer")
    ) {
      history.push(
        `/peer/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (location.pathname.includes("person_peer")) {
      history.push(
        `/person_peer/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (location.pathname.includes("compare_reason_by_organization")) {
      history.push(
        `/compare_reason_by_organization/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (location.pathname.includes("stop-reason")) {
      history.push(
        `/stop-reason'/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (
      location.pathname.includes("stop-outcome") &&
      !location.pathname.includes("stop-outcome-peer")
    ) {
      history.push(
        `/stop-outcome'/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
    if (location.pathname.includes("stop-outcome-peer")) {
      history.push(
        `/stop-outcome-peer'/${current.asOfDate}/${current.timeSpanUnit}/${current.organization}`
      );
    }
  };

  useEffect(() => {
    location.pathname === "/" ? setHideBack(true) : setHideBack(false);
  }, [location]);

  return (
    <DashboardLayoutContext.Provider
      value={{
        dimensionLookups,
        setPageHeading,
        setFetching,
        breadCrumb,
        setBreadCrumb,
      }}
    >
      <div className={classes.DashboardLayout}>
        <aside className={classes.Sidebar}>
          {session ? (
            <>
              <UserCard user={session.currentUser} />
              <div className={classes.NavBox}>
                <div>
                  <div className={classes.NewReportBox}></div>
                  <Navigation permissions={session.authorities} />
                </div>
                <div>
                  <button className={classes.SidebarButton}>
                    <img src={Logo} alt="Benchmark" />
                    <FontAwesomeIcon
                      className={classes.CollapseIcon}
                      icon={["fas", "chevron-left"]}
                    />
                  </button>
                  <div className={classes.SideFooter}>
                    <a href="https://benchmarkanalytics.com/contact">
                      <span>Customer Support</span>
                    </a>
                    <div className={classes.CopyRight}>
                      © 2020 Benchmark Analytics • All Rights Reserved • V1.16
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </aside>
        <div className={classes.ContentFrame}>
          <header className={classes.Header}>
            <div
              className={`${classes.Breadcrumb} ${
                hideBack ? classes.Hide : ""
              }`}
            >
              <NavLink className={classes.Base} to="/">
                <FontAwesomeIcon icon={["fas", "chart-line"]} />
                <span>Analytics dashboard / </span>
              </NavLink>
              <span className={classes.Dynamic}>{breadCrumb}</span>
            </div>
            <h1>
              <NavLink
                to="/"
                className={`${classes.Back} ${hideBack ? classes.Hide : ""}`}
              >
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
              </NavLink>
              {pageHeading}
            </h1>
          </header>
          <div className={classes.FilterBar}>
            <FilterBar
              onChange={filterChanged}
              timeSpanFilters={timeSpanFilters}
              orgMetaData={organizationFilters}
              fetching={fetching}
            />
          </div>
          <div className={classes.RouterFrame}>
            <Switch>
              <Route exact path="/">
                <Dashboard
                  timeSpanUnit={timeSpanUnit}
                  organization={organization}
                  asOfDate={asOfDate}
                />
              </Route>
              <Route path="/peer/:asOfDate/:timeSpanUnit/:organization">
                <PeerComparisonsPage />
              </Route>
              <Route path="/person_peer/:asOfDate/:timeSpanUnit/:organization">
                <PersonPeerPage />
              </Route>
              <Route path="/stop-trooper-scatterplot/:asOfDate/:timeSpanUnit/:organization">
                <TrooperScatterPlotPage />
              </Route>
              <Route path="/compare_reason_by_organization/:asOfDate/:timeSpanUnit/:organization">
                <CompareReasonByOrganization />
              </Route>
              <Route path="/event/:type/:asOfDate/:timeSpanUnit/:organization">
                <EventPage />
              </Route>
              <Route path="/analysis/:asOfDate/:timeSpanUnit/:organization">
                <AnalysisPage
                  filters={{ asOfDate, timeSpanUnit, organization }}
                />
              </Route>
              <Route path="/stop-reason/:asOfDate/:timeSpanUnit/:organization">
                <StopReasonsPage />
              </Route>
              <Route path="/stop-outcome/:asOfDate/:timeSpanUnit/:organization">
                <StopOutcomePage />
              </Route>
              <Route path="/stop-outcome-peer/:asOfDate/:timeSpanUnit/:organization">
                <StopOutcomePeerPage />
              </Route>
              <Route path="/post-stop-interaction/:asOfDate/:timeSpanUnit/:organization">
                <PostStopInteractionPage />
              </Route>
              <Route path="/post-stop-interaction-peer/:asOfDate/:timeSpanUnit/:organization">
                <PostStopInteractionPeerComparisonPage />
              </Route>
              <Route path="/test/:asOfDate/:timeSpanUnit/:organization">
                <TestPage />
              </Route>
            </Switch>
            <div className={classes.AnalysisFrame}>
              <AnalysisBar
                analyses={analysesBarData}
                asOfDate={asOfDate}
                timeSpanUnit={timeSpanUnit}
                organization={organization}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayoutContext.Provider>
  );
}

export default withRouter(DashboardLayout);
