import axios from "axios";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

export const GENDER = "Gender";
export const RACE = "Race";
export const AGE = "Age";

export const DIMENSION_TYPES = [
  { key: GENDER, title: "Gender" },
  { key: RACE, title: "Race" },
  { key: AGE, title: "Age Group" },
];

export const getDimensionLookup = async () => {
  let token = localStorage.getItem(TOKEN_KEY);
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const [gender, race, age] = (
    await Promise.all([
      axios.get(`${BASE_URL}/dimensions?type=Gender`, options),
      axios.get(`${BASE_URL}/dimensions?type=Race`, options),
      axios.get(`${BASE_URL}/dimensions?type=Age`, options),
    ])
  ).map((result) => result.data);
  return { [GENDER]: gender, [RACE]: race, [AGE]: age };
};
