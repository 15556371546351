import React from "react";
import classes from "./AnalysisBar.module.scss";
import { NavLink } from "react-router-dom";

function AnalysisBar({ analyses, asOfDate, timeSpanUnit, organization }) {
  return (
    <div className={classes.AnalysisBar}>
      <header>
        <h2>Analyses</h2>
      </header>
      <ul className={classes.AnalysesList}>
        {analyses
          ? analyses.map((analysis) => (
              <li key={analysis.id}>
                <NavLink
                  className={classes.AnaysesLink}
                  to={`${analysis.route}${asOfDate}/${timeSpanUnit}/${organization}`}
                >
                  {analysis.name}
                </NavLink>
                <ul>
                  {analysis.children
                    ? analysis.children.map((subanalysis) => (
                        <li key={subanalysis.id}>
                          <NavLink
                            className={classes.AnaysesLink}
                            to={`${subanalysis.route}${asOfDate}/${timeSpanUnit}/${organization}`}
                          >
                            {subanalysis.name}
                          </NavLink>
                        </li>
                      ))
                    : null}
                </ul>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default AnalysisBar;
