// TODO: should be taken from BE

import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/reason_compare_reason_by_organization",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    type: true,
    dimension: true,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "Compare Reason By Organization",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Organization",
        dataIndex: "organization_name",
      },
      {
        title: "Total",
        dataIndex: "org_total_count",
      },
      {
        title: "Moving Violation",
        dataIndex: "org_moving",
      },
      {
        title: "Peer Moving Violation",
        dataIndex: "org_moving_residual",
      },
      {
        title: "Nonmoving Violation",
        dataIndex: "org_non_moving",
      },
      {
        title: "Peer Nonmoving Violation",
        dataIndex: "org_non_moving_residual",
      },
      {
        title: "Other",
        dataIndex: "org_other_moving",
      },
      {
        title: "Peer Other",
        dataIndex: "org_other_moving_residual",
      },
    ],
  },
};
