// TODO: should be taken from BE

import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/person_by_age_with_peer",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "By Subject Age",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Name",
        dataIndex: "officer_full_name",
      },
      {
        title: "Title",
        dataIndex: "title",
      },
      {
        title: "Badge Number",
        dataIndex: "badge_number",
      },
      {
        title: "0-16",
        children: [
          {
            title: "Pct",
            dataIndex: ["0-16", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["0-16", "peer_summary"],
          },
        ],
      },
      {
        title: "17-20",
        children: [
          {
            title: "Pct",
            dataIndex: ["17-20", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["17-20", "peer_summary"],
          },
        ],
      },
      {
        title: "21-24",
        children: [
          {
            title: "Pct",
            dataIndex: ["21-24", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["21-24", "peer_summary"],
          },
        ],
      },
      {
        title: "25-29",
        children: [
          {
            title: "Pct",
            dataIndex: ["25-29", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["25-29", "peer_summary"],
          },
        ],
      },
      {
        title: "30-34",
        children: [
          {
            title: "Pct",
            dataIndex: ["30-34", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["30-34", "peer_summary"],
          },
        ],
      },
      {
        title: "35-39",
        children: [
          {
            title: "Pct",
            dataIndex: ["35-39", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["35-39", "peer_summary"],
          },
        ],
      },
      {
        title: "40-49",
        children: [
          {
            title: "Pct",
            dataIndex: ["40-49", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["40-49", "peer_summary"],
          },
        ],
      },
      {
        title: "50-64",
        children: [
          {
            title: "Pct",
            dataIndex: ["50-64", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["50-64", "peer_summary"],
          },
        ],
      },
      {
        title: "65+",
        children: [
          {
            title: "Pct",
            dataIndex: ["65+", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["65+", "peer_summary"],
          },
        ],
      },
      {
        title: "Unknown Age",
        children: [
          {
            title: "Pct",
            dataIndex: ["Unknown Age", "pct_summary"],
          },
          {
            title: "Peer",
            dataIndex: ["Unknown Age", "peer_summary"],
          },
        ],
      },
    ],
  },
};
