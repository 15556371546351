import React, { useState, useEffect, useContext } from "react";
import classes from "./Dashboard.module.scss";
import { getEventDetails } from "../../services/DashboardService";
import { createLabelAndCalculateDomainMargin } from "../../services/DataVisualizationService";
import { getChartData } from "../../services/DashLayoutService";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import EventLink from "../../components/EventLinkComponent/EventLink";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";

function Dashboard({ organization, asOfDate, timeSpanUnit }) {
  const [eventInfo, setEventInfo] = useState([]);
  const [data, setData] = useState(null);
  const [domainMargin, setDomainMargin] = useState(null);

  const dashLayoutContext = useContext(DashboardLayoutContext);

  useEffect(() => {
    dashLayoutContext.setPageHeading("Analytics Dashboard");
  }, [dashLayoutContext]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventDetails = await getEventDetails(
          asOfDate,
          timeSpanUnit,
          organization
        );
        if (eventDetails) {
          if (!eventDetails[0])
            throw new Error("eventDetails[0] is null or empty.");
          setEventInfo(eventDetails[0].events);
        }
      } catch (error) {
        // TODO: set toast notification
        console.log("Error initializing dashboard page", error);
      }
    };
    if (asOfDate && timeSpanUnit && organization) {
      fetchEventDetails();
    }
  }, [organization, asOfDate, timeSpanUnit]);

  useEffect(() => {
    try {
      const fetchChartData = async () => {
        const chartData = await getChartData(organization);
        const dataAndDomainMargin = createLabelAndCalculateDomainMargin(
          chartData.data
        );
        setData(dataAndDomainMargin.data);
        setDomainMargin(dataAndDomainMargin.domainMargin);
      };
      if (organization) {
        fetchChartData();
      }
    } catch (error) {
      // TODO: set toast notification
      console.log("Error fetching or setting chart data", error);
    }
  }, [organization]);

  return (
    <div className={classes.Dashboard}>
      <div className={classes.ChartBox}>
        <header>
          <h2>Active Officers by Suborganization</h2>
        </header>
        {data && domainMargin ? (
          <ResponsiveContainer width="99%" height={300}>
            <BarChart
              data={data}
              height={300}
              margin={{ top: 40, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                tick={{ fontSize: "7px" }}
                dataKey="organization_name"
                allowDataOverflow={true}
              ></XAxis>
              <YAxis
                allowDecimals={false}
                domain={[0, `dataMax + ${domainMargin}`]}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="n_persons" fill="#8884d8" name="Employees" />
            </BarChart>
          </ResponsiveContainer>
        ) : null}
      </div>
      {eventInfo.length && asOfDate && timeSpanUnit && organization ? (
        <div className={classes.EventBox}>
          {eventInfo ? (
            <EventLink
              eventData={eventInfo[0]}
              asOfDate={asOfDate}
              timeSpanUnit={timeSpanUnit}
              organization={organization}
            />
          ) : (
            <div>no event info loaded</div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Dashboard;
