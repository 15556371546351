import React, { useEffect } from "react";
import classes from "./FilterBar.module.scss";
import { Select, DatePicker, AutoComplete } from "antd";
import moment from "moment";

function FilterBar({ onChange, timeSpanFilters, orgMetaData, fetching }) {
  const { Option } = Select;

  useEffect(() => {
    if (orgMetaData) {
      orgMetaData.forEach((item) => {
        item.value = item.name;
        item.is_active = item.is_active.toString();
      });
    }
  }, [orgMetaData]);

  const orgChanged = (value) => {
    const org = orgMetaData.find((item) => item.name === value);
    if (org) {
      onChange({ type: "org", value: org.organization_id });
    }
  };

  const rangeChanged = (value) => {
    onChange({ type: "unit", value });
  };

  const dateChanged = (value) => {
    onChange({ type: "date", value });
  };

  return (
    <div className={classes.FilterBar}>
      {orgMetaData && orgMetaData[0] ? (
        <AutoComplete
          onChange={orgChanged}
          style={{ width: 240 }}
          defaultValue={orgMetaData[0].name}
          disabled={fetching}
          options={orgMetaData}
          filterOption={(inputValue, option) => {
            if (option && option.value && inputValue) {
              return (
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              );
            }
          }}
        />
      ) : null}

      {timeSpanFilters && timeSpanFilters[0] ? (
        <Select
          onChange={rangeChanged}
          style={{ width: 240, marginLeft: "2rem" }}
          defaultValue={timeSpanFilters[0].range_unit}
          disabled={fetching}
        >
          <Option disabled value="">
            select date range
          </Option>
          {timeSpanFilters
            ? timeSpanFilters.map((filter) => (
                <Option key={filter.id} value={filter.range_unit}>
                  {filter.range_unit}
                </Option>
              ))
            : null}
        </Select>
      ) : null}

      <DatePicker
        defaultValue={moment().subtract(1, "days")}
        onChange={dateChanged}
        style={{ width: 240, marginLeft: "2rem" }}
        disabled={fetching}
      />
    </div>
  );
}

export default FilterBar;
