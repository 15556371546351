import React, { useEffect, useContext, useState } from "react";
import classes from "./PersonPeerPage.module.scss";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import { getComponentByType } from "../../base-visualization/visualization-protocol";
import { MV_STOP_DEMOGRAPHICS_BY_TROOPER_TITLE } from "../../layouts/DashboardLayoutComponent/DashboardLayout";

import {
  ENDPOINT as ENDPOINT1,
  VISUALIZATION as VISUALIZATION1,
} from "./person-by-gender-with-peer-data";
import {
  ENDPOINT as ENDPOINT2,
  VISUALIZATION as VISUALIZATION2,
} from "./person-by-race-with-peer-data";
import {
  ENDPOINT as ENDPOINT3,
  VISUALIZATION as VISUALIZATION3,
} from "./person-by-age-with-peer-data";

function PersonPeerPage({ queryParams, segmentParams }) {
  const [initialized, setInitialized] = useState(null);
  // TODO: We don't really need context here, we may pass `setPageHeader` function from upper level
  // Or (which more correct) manage the page title from outside
  const dashLayoutContext = useContext(DashboardLayoutContext);
  useEffect(() => {
    if (!initialized) {
      dashLayoutContext.setPageHeading(MV_STOP_DEMOGRAPHICS_BY_TROOPER_TITLE);
      dashLayoutContext.setBreadCrumb(<span>Motor Vehicle Stops</span>);
      setInitialized(true);
    }
  }, [dashLayoutContext, initialized]); // TODO: it will rerender more then needed

  // TODO: components must be defined as part of GenericPageLayout
  const Component1 = getComponentByType(VISUALIZATION1.type);
  const Component2 = getComponentByType(VISUALIZATION3.type);
  const Component3 = getComponentByType(VISUALIZATION2.type);

  // Merge all parameters from segment, query & custom
  // `organization` -> `organization_id`, `asOfDate` -> `date_id`...  because it is used in endpoint query param.
  // TODO: make all places same names - so no conversion needed
  const params = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
    },
    queryParams
  );

  return (
    <div className={classes.PersonPeerRoot}>
      <div className={classes.Content}>
        <Component1
          {...{ endpoint: ENDPOINT1, visualization: VISUALIZATION1, params }}
        />
      </div>
      <div className={classes.Content}>
        <Component3
          {...{ endpoint: ENDPOINT2, visualization: VISUALIZATION2, params }}
        />
      </div>
      <div className={classes.Content}>
        <Component2
          {...{ endpoint: ENDPOINT3, visualization: VISUALIZATION3, params }}
        />
      </div>
    </div>
  );
}

export default withParams(PersonPeerPage);
