import axios from "axios";
import { mockSession } from "../mock-data/DashLayoutMock";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

export const tryGetToken = (location, history) => {
  let token = localStorage.getItem(TOKEN_KEY);
  if (location.hash) {
    const decodedHash = decodeURI(location.hash);
    token = JSON.parse(decodedHash.split("=")[1]);
    localStorage.setItem(TOKEN_KEY, token);
  } else {
    if (
      !token &&
      !location.pathname.toLowerCase().includes("/status") &&
      !location.pathname.toLowerCase().includes("/unauthorized")
    ) {
      return history.push("/unauthorized");
    }
  }

  if (location.hash) {
    history.push("/");
  }
  return token;
};

export const initializeDashboardLayout = async () => {
  return mockSession;
};

export const getTimeSpanFilters = async () => {
  let token = localStorage.getItem(TOKEN_KEY);
  const query = config.mock ? "?mock=mock" : "";
  const response = await axios.get(`${BASE_URL}/time-filters${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getOrganizationFilters = async () => {
  const query = config.mock ? "?mock=mock" : "";
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(`${BASE_URL}/organization${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = response.data
    .filter((r) => r.is_active)
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  return data;
};

export const getChartData = async (orgId) => {
  const query = config.mock ? `?mock=1&org_id=${orgId}` : `?org_id=${orgId}`;
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(`${BASE_URL}/report/person_by_org${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getAnalysisBarData = async () => {
  const query = config.mock ? "?mock=mock" : "";
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(`${BASE_URL}/analysis${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const biasGenderAnalysis = async (orgId, date, unit) => {
  const query = config.mock
    ? "?mock=mock"
    : `?org_id=${orgId}&date=${date}&unit=${unit}`;
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(
    `${BASE_URL}/report/mv_stop_bias_by_gender${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const biasRaceAnalysis = async (orgId, date, unit) => {
  const qstring = `?org_id=${orgId}&date=${date}&unit=${unit}`;
  const query = config.mock ? "?mock=mock" : qstring;
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(
    `${BASE_URL}/report/mv_stop_bias_by_race${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const biasAgeAnalysis = async (orgId, date, unit) => {
  const query = config.mock
    ? "?mock=mock"
    : `?org_id=${orgId}&date=${date}&unit=${unit}`;
  let token = localStorage.getItem(TOKEN_KEY);
  const response = await axios.get(
    `${BASE_URL}/report/mv_stop_bias_by_age${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const biasDataTransform = (data) => {
  let counter = 0;
  data.forEach((item) => {
    item.key = counter += 1;
    item.pctValue = `${Math.round(item.pct_value * 100)}%`;
    item.peerAverageValue = `${Math.round(item.peer_average_value * 100)}%`;
  });

  return data;
};
