import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/report-outcome-by-organization",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    mock: false,
    type: true,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "By Subject Race",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Category",
        dataIndex: "dimension",
      },
      {
        title: "Total",
        dataIndex: "org_dim_total",
      },
      {
        title: "Summons Moving",
        dataIndex: "org_summons_moving",
      },
      {
        title: "Peer Summons Moving",
        dataIndex: "org_summons_moving_residual",
      },
      {
        title: "Summons Nonmoving",
        dataIndex: "org_summons_non_moving",
      },
      {
        title: "Peer Summons Nonmoving",
        dataIndex: "org_summons_non_moving_residual",
      },
      {
        title: "Warnings Moving",
        dataIndex: "org_warnings_moving",
      },
      {
        title: "Peer Warnings Moving",
        dataIndex: "org_warnings_moving_residual",
      },
      {
        title: "Warnings Nonmoving",
        dataIndex: "org_warnings_non_moving",
      },
      {
        title: "Peer Warnings Nonmoving",
        dataIndex: "org_warnings_non_moving_residual",
      },
      {
        title: "No Enforcement",
        dataIndex: "org_no_enforcement",
      },
      {
        title: "Peer No Enforcement",
        dataIndex: "org_no_enforcement_residual",
      },
    ],
  },
};
