import React, { useEffect, useContext, useState } from "react";
import { Select } from "antd";

import { getComponentByType } from "../../base-visualization/visualization-protocol";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import { MV_STOP_OUTCOME_BY_PEER_COMPARISON } from "../../layouts/DashboardLayoutComponent/DashboardLayout";
import classes from "./StopOutcomePeerComparison.module.scss";
import { DIMENSION_TYPES } from "../../services/DimensionsService";

import { ENDPOINT, VISUALIZATION } from "./backend-component-definitions";

const { Option } = Select;

const StopOutcomePeerComparison = ({ segmentParams, queryParams }) => {
  const [type, setType] = useState(null);
  const [dimension, setDimension] = useState(null);

  // TODO: We don't really need context here, we may pass `setPageHeader` function from upper level
  // Or (which more correct) manage the page title from outside

  const dashLayoutContext = useContext(DashboardLayoutContext);
  useEffect(() => {
    dashLayoutContext.setPageHeading(MV_STOP_OUTCOME_BY_PEER_COMPARISON);
    typeChange(DIMENSION_TYPES[0].key); // Make default selection on component mount
    dashLayoutContext.setBreadCrumb(<span>Motor Vehicle Stops</span>);
  }, []);

  function typeChange(v) {
    setType(v);
    const selectedDimension = dashLayoutContext.dimensionLookups[v];
    if (selectedDimension) {
      setDimension(selectedDimension[0]);
    } else {
      setDimension(null);
    }
  }

  // TODO: components must be defined as part of GenericPageLayout
  const TheTable = getComponentByType(VISUALIZATION.type);

  const TypeDropdown = (
    <Select
      placeholder="Select Dimension Type"
      style={{ width: 300, marginLeft: "2rem" }}
      onChange={typeChange}
      value={type}
    >
      {DIMENSION_TYPES.map((t) => (
        <Option key={t.key} value={t.key}>
          {t.title}
        </Option>
      ))}
    </Select>
  );

  const selectedDimension = dashLayoutContext.dimensionLookups[type] || [];
  const DimensionDropdown = (
    <Select
      placeholder="Select Dimension"
      style={{ width: 300, marginLeft: "2rem" }}
      onChange={(v) => {
        setDimension(v);
      }}
      value={dimension}
    >
      {selectedDimension.map((t, ix) => (
        <Option key={ix} value={t}>
          {t}
        </Option>
      ))}
    </Select>
  );

  // Merge all parameters from segment, query & custom
  // `organization` -> `organization_id`, `asOfDate` -> `date_id`...  because it is used in endpoint query param.
  // TODO: make all places same names - so no conversion needed
  const params = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
      type,
      dimension,
    },
    queryParams
  );

  return (
    <div className={classes.PersonPeerRoot}>
      {TypeDropdown} {DimensionDropdown}
      <div className={classes.Content}>
        <TheTable
          {...{ endpoint: ENDPOINT, visualization: VISUALIZATION, params }}
        />
      </div>
    </div>
  );
};

export default withParams(StopOutcomePeerComparison);
