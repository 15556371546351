export const linksPermissions = {
    home: [
        'everybody'
    ],
    people: [
        'configureDataImport',
        'viewAllUserProfiles'
    ],
    'officer profile': [
        'viewOwnOfficerProfile',
        'viewAllUserProfiles'
    ],
    'my profile': [
        'everybody'
    ],
    analytics: [
        'viewForms',
        'reviewForm',
        'submitUOFReport',
        'submitVPReport',
        'submitDORReport',
        'submitIACReport',
        'submitIAIReport',
        'reviewUOFReport',
        'viewAllUOFReports',
        'reviewVPReport',
        'viewAllVPReports',
        'reviewDORReport',
        'viewAllDailyObservationReports',
        'viewOwnDailyObservationReports',
        'reviewIACReport',
        'reviewIAIReport',
        'viewAllInvestigations',
        'viewAllIntakes',
        'submitReport',
        'reviewReport',
        'viewReports'
    ],
    reports: [
        'viewForms',
        'reviewForm',
        'submitUOFReport',
        'submitVPReport',
        'submitDORReport',
        'submitIACReport',
        'submitIAIReport',
        'reviewUOFReport',
        'viewAllUOFReports',
        'reviewVPReport',
        'viewAllVPReports',
        'reviewDORReport',
        'viewAllDailyObservationReports',
        'viewOwnDailyObservationReports',
        'reviewIACReport',
        'reviewIAIReport',
        'viewAllInvestigations',
        'viewAllIntakes',
        'submitReport',
        'reviewReport',
        'viewReports'
    ],
    training: [
        'accessTraining'
    ],
    'internal affairs': [
            'viewCaseLoad',
            'viewCaseLoadAsIADesignator',
            'viewOwnCaseLoad',
    ],
    admin: [
          'manageAgencies',
          'createAnyUser',
          'createAgencyUser',
          'updateAnyUser',
          'updateAgencyUser',
          'manageWorkflows',
          'accessAnalyticsAdmin',
          'accessAnalyticsAdmin',
          'viewAllReports',
          'accessTrainingAdminPanel',
          'createRole',
    ],
    settings: [
        'everybody'
    ],
    logout: [
        'everybody'
    ]
};

export const statsLinksPermissions = {
    accessStatsDesigner: [
        'accessStatsDesigner'
    ],
    accessStatsAdmin: [
        'accessStatsAdmin'
    ],
    accessStatsReports: [
        'accessStatsReports'
    ]
}