import axios from "axios";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

export function getApi(url) {
  let token = localStorage.getItem(TOKEN_KEY);
  return new Promise((accept, reject) => {
    const options = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .get(`${BASE_URL}/${url}`, options)
      .then((resopnse) => {
        accept(resopnse.data);
      })
      .catch(reject);
  });
}

export function postApi(url) {
  //TODO: TBD
}
