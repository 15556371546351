import axios from "axios";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

export const ageColumns = [
  {
    title: "Organization",
    dataIndex: "organization_name",
    key: "organization_name",
    width: 150,
  },
  {
    title: "Ages 0-16",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "0-16_pct_summary",
        key: "0-16_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "0-16_peer_summary",
        key: "0-16_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 17-20",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "17-20_pct_summary",
        key: "17-20_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "17-20_peer_summary",
        key: "17-20_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 21-24",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "21-24_pct_summary",
        key: "21-24_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "21-24_peer_summary",
        key: "21-24_peer_summary",
        width: 100,
      },
    ],
  },
  {
    title: "Ages 25-29",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "25-29_pct_summary",
        key: "25-29_pct_summary",
        width: 100,
      },
      {
        title: "Peer",
        dataIndex: "25-29_peer_summary",
        key: "25-29_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 30-34",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "30-34_pct_summary",
        key: "30-34_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "30-34_peer_summary",
        key: "30-34_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 35-39",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "35-39_pct_summary",
        key: "35-39_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "35-39_peer_summary",
        key: "35-39_peer_summary",
        width: 100,
      },
    ],
  },
  {
    title: "Ages 40-49",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "40-49_pct_summary",
        key: "40-49_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "40-49_peer_summary",
        key: "40-49_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 50-64",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "50-64_pct_summary",
        key: "50-64_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "50-64_peer_summary",
        key: "50-64_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Ages 65+",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "65+_pct_summary",
        key: "65+_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "65+_peer_summary",
        key: "65+_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Unknown Age",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Unknown Age_pct_summary",
        key: "Unknown Age_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Unknown Age_peer_summary",
        key: "Unknown Age_peer_summary",
        width: 80,
      },
    ],
  },
];

export const genderColumns = [
  {
    title: "Organization",
    dataIndex: "organization_name",
    key: "organization_name",
    width: 150,
  },
  {
    title: "Female",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Female_pct_summary",
        key: "Female_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Female_peer_summary",
        key: "Female_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Male",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Male_pct_summary",
        key: "Male_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Male_peer_summary",
        key: "Male_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Unknown Gender",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Unknown Gender_pct_summary",
        key: "Unknown Gender_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Unknown Gender_peer_summary",
        key: "Unknown Gender_peer_summary",
        width: 80,
      },
    ],
  },
];

export const raceColumns = [
  {
    title: "Organization",
    dataIndex: "organization_name",
    key: "organization_name",
    width: 150,
  },
  {
    title: "White",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "White_pct_summary",
        key: "White_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "White_peer_summary",
        key: "White_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Black",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Black_pct_summary",
        key: "pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Black_peer_summary",
        key: "Black_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Hispanic",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Hispanic_pct_summary",
        key: "Hispanic_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Hispanic_peer_summary",
        key: "Hispanic_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Asian",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Asian_pct_summary",
        key: "Asian_pct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Asian_peer_summary",
        key: "Asian_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "American Indian",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "American Indian/Alaskan Native_pct_summary",
        key: "American Indian/Alaskan Nativepct_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "American Indian/Alaskan Native_peer_summary",
        key: "American Indian/Alaskan Native_peer_summary",
        width: 80,
      },
    ],
  },
  {
    title: "Unknown Race",
    children: [
      {
        title: "Pct (Count)",
        dataIndex: "Unknown Race_pct_summary",
        key: "Unknown Race_summary",
        width: 80,
      },
      {
        title: "Peer",
        dataIndex: "Unknown Race_peer_summary",
        key: "Unknown Race_peer_summary",
        width: 80,
      },
    ],
  },
];

const flatten = (data) => {
  const flatData = {};
  const keys = Object.keys(data);
  keys.forEach((key) => {
    if (typeof data[key] == "object") {
      const subKeys = Object.keys(data[key]);
      subKeys.forEach((subKey) => {
        flatData[`${key}_${subKey}`] = data[key][subKey];
      });
    } else {
      flatData[key] = data[key];
    }
  });

  return [{ ...flatData, key: 0 }];
};

export const getGenderPeerDetails = async (
  asOfDate,
  timeSpanUnit,
  organization
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  const query = config.mock
    ? "?mock=mock"
    : `?organization_id=${organization}&date_id=${asOfDate}&date_unit=${timeSpanUnit}`;
  const response = await axios.get(
    `${BASE_URL}/report/organization_by_gender_with_peer${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.data && response.data[0]) {
    return flatten(response.data[0]);
  } else {
    return [];
  }
};

export const getRacePeerDetails = async (
  asOfDate,
  timeSpanUnit,
  organization
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  const query = config.mock
    ? "?mock=mock"
    : `?organization_id=${organization}&date_id=${asOfDate}&date_unit=${timeSpanUnit}`;
  const response = await axios.get(
    `${BASE_URL}/report/organization_by_race_with_peer${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.data && response.data[0]) {
    return flatten(response.data[0]);
  } else {
    return [];
  }
};

export const getAgePeerDetails = async (
  asOfDate,
  timeSpanUnit,
  organization
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  const query = config.mock
    ? "?mock=mock"
    : `?organization_id=${organization}&date_id=${asOfDate}&date_unit=${timeSpanUnit}`;
  const response = await axios.get(
    `${BASE_URL}/report/organization_by_age_with_peer${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.data && response.data[0]) {
    return flatten(response.data[0]);
  } else {
    return [];
  }
};
