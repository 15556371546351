import React, { useEffect, useState, useContext } from "react";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import {
  mvStopDetails,
  mvStopDetailsCount,
  columnDefinitionsByType,
  objectToQueryString,
} from "../../services/EventService";
import classes from "./Event.module.scss";
import { Table, Pagination } from "antd";
import DashLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";

/**
 *  Supported query params: page, page_size, by_subject_age, by_subject_gender, by_subject_race ...
 */
function Event({ queryParams, segmentParams }) {
  const [searchParams, setSearchParams] = useState({ page: 0, page_size: 20 });
  const [total, setTotal] = useState(null);
  const [defs, setDefs] = useState([]);
  const [data, setDetails] = useState([]);
  const [fetching, setFetching] = useState(null);

  const dashLayoutContext = useContext(DashLayoutContext);

  useEffect(() => {
    dashLayoutContext.setPageHeading(`${segmentParams.type} Events`);
  }, [dashLayoutContext, segmentParams]);

  const fetchCountAndOrDetails = async (
    getDetails = true,
    getCount = false
  ) => {
    try {
      setFetching(true);
      if (getCount) {
        const total = await mvStopDetailsCount(
          segmentParams.asOfDate,
          segmentParams.timeSpanUnit,
          segmentParams.organization,
          objectToQueryString(Object.assign({}, queryParams, searchParams))
        );
        setTotal(total);
      }
      if (getDetails) {
        const queryStr = objectToQueryString(
          Object.assign({}, queryParams, searchParams)
        );
        const details = await mvStopDetails(
          segmentParams.asOfDate,
          segmentParams.timeSpanUnit,
          segmentParams.organization,
          queryStr
        );
        const data = details.map((d, ix) => {
          d.key = ix;
          return d;
        });
        setDetails(data);
      }
    } catch (error) {
      console.log("Error fetching count or details", error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    setDefs(columnDefinitionsByType(segmentParams.type));
    if (!fetching) {
      fetchCountAndOrDetails(true, true);
    }
    // dont want to rerender on fetching change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentParams]);

  useEffect(() => {
    fetchCountAndOrDetails(true, false);
  }, [searchParams]);

  const onPageChange = (p) => {
    const page = p - 1;
    const newSearchParams = Object.assign({}, searchParams, { page });
    setSearchParams(newSearchParams);
  };

  return (
    <div className={classes.EventBox}>
      <div className={classes.EventContent}>
        {data ? (
          <>
            <div className={classes.TableBox}>
              <h2>{segmentParams.type}</h2>
              <Table
                scroll={{ x: 3500 }}
                dataSource={data}
                columns={defs}
                pagination={false}
              />
            </div>
            <div className={classes.PaginationBox}>
              <Pagination
                current={searchParams.page + 1}
                pageSize={searchParams.page_size}
                total={total}
                onChange={onPageChange}
              />
            </div>
          </>
        ) : (
          <h3>Loading event data...</h3>
        )}
      </div>
    </div>
  );
}

export default withParams(Event);
