import React from "react";
import classes from "./Test.module.scss";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import { ENDPOINT, VISUALIZATION } from "./reasons-by-race-data";
import { getComponentByType } from "../../base-visualization/visualization-protocol";

const Test = ({ queryParams, segmentParams }) => {
  const Component = getComponentByType(VISUALIZATION.type);

  const params = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
    },
    queryParams
  );

  console.log("Params", params);
  console.log("Cmponent", Component);

  return (
    <div className={classes.Root}>
      <div className={classes.Content}>
        <Component
          {...{ endpoint: ENDPOINT, visualization: VISUALIZATION, params }}
        />
      </div>
    </div>
  );
};

export default withParams(Test);
