import axios from "axios";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

const mvStopColumnDefinitions = [
  {
    title: "Trooper",
    dataIndex: "officer_full_name",
    key: "Trooper",
  },
  {
    title: "Incident Number",
    dataIndex: "incident_number",
    key: "Incident Number",
  },
  {
    title: "Date/Time of Stop",
    dataIndex: "start_event_ts",
    key: "Date/Time of Stop",
  },
  {
    title: "End of Stop",
    dataIndex: "end_event_ts",
    key: "End of Stop",
  },
  {
    title: "Summons Moving",
    dataIndex: "summons_moving",
    key: "Summons Moving",
  },
  {
    title: "Summons Non-Moving",
    dataIndex: "summons_non_moving",
    key: "Summons Non-Moving",
  },
  {
    title: "Warnings Moving",
    dataIndex: "warnings_moving",
    key: "Warnings Moving",
  },
  {
    title: "Warnings Non-Moving",
    dataIndex: "warnings_non_moving",
    key: "Warnings Non-Moving",
  },
  {
    title: "Subject Age",
    dataIndex: "subject_age",
    key: "Subject Age",
  },
  {
    title: "Subject Gender",
    dataIndex: "subject_gender",
    key: "Subject Gender",
  },
  {
    title: "Subject Race",
    dataIndex: "subject_race",
    key: "Subject Race",
  },
  {
    title: "Statute",
    dataIndex: "cad_statute",
    key: "Statute",
  },
  {
    title: "MV Flag",
    dataIndex: "moving_vehicle_flag",
    key: "MV Flag",
  },
  {
    title: "MV Video Flag",
    dataIndex: "moving_vehicle_video_flag",
    key: "MV Video Flag",
  },
  {
    title: "State",
    dataIndex: "vehicle_state",
    key: "State",
  },
  {
    title: "Primary Organization",
    dataIndex: "primary_organization",
    key: "Primary Organization",
  },
];

// !Note: if params changed, mvStopDetailsCount suppose to be synchronized (same parameters)
export const mvStopDetails = async (
  asOfDate,
  timeSpanUnit,
  organization,
  additionalQuery = ""
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  let query = `?by_organization=${organization}&date_unit=${timeSpanUnit}&date_id=${asOfDate}${additionalQuery}`;
  if (config.mock) {
    query += "&mock=mock";
  }
  const response = await axios.get(
    `${BASE_URL}/report/mv_stop_details${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// !Note: if mvStopDetails params changed, this suppose to be synchronized (same parameters)
export const mvStopDetailsCount = async (
  asOfDate,
  timeSpanUnit,
  organization,
  additionalQuery = ""
) => {
  let token = localStorage.getItem(TOKEN_KEY);
  let query = `?by_organization=${organization}&date_unit=${timeSpanUnit}&date_id=${asOfDate}&${additionalQuery}`;
  if (config.mock) {
    query += "&mock=mock";
  }
  const response = await axios.get(
    `${BASE_URL}/report/mv_stop_details_count${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.total;
};

export const columnDefinitionsByType = (type) => {
  // TODO: we not really using type here
  return mvStopColumnDefinitions;
};

export const objectToQueryString = (obj) => {
  const keys = Object.keys(obj);
  let str = "";
  keys.forEach((key) => {
    if (key.length > 0) {
      str += `&${key}=${obj[key]}`;
    }
  });
  return str;
};
