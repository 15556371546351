import React, { useEffect, useContext, useState } from "react";
import classes from "./PostStopInteraction.module.scss";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import { getComponentByType } from "../../base-visualization/visualization-protocol";
import { MV_POST_STOP_INTERACTION_BY_ORGANIZATION_TITLE } from "../../layouts/DashboardLayoutComponent/DashboardLayout";

import {
  ENDPOINT as ENDPOINT1,
  VISUALIZATION as VISUALIZATION1,
} from "./interaction-by-race-data";
import {
  ENDPOINT as ENDPOINT2,
  VISUALIZATION as VISUALIZATION2,
} from "./interaction-by-gender-data";
import {
  ENDPOINT as ENDPOINT3,
  VISUALIZATION as VISUALIZATION3,
} from "./interaction-by-age-data";

const PostStopInteraction = ({ segmentParams, queryParams }) => {
  const [initialized, setInitialized] = useState(null);
  // TODO: We don't really need context here, we may pass `setPageHeader` function from upper level
  // Or (which more correct) manage the page title from outside
  const dashLayoutContext = useContext(DashboardLayoutContext);
  useEffect(() => {
    if (!initialized) {
      dashLayoutContext.setPageHeading(
        MV_POST_STOP_INTERACTION_BY_ORGANIZATION_TITLE
      );
      dashLayoutContext.setBreadCrumb(<span>Motor Vehicle Stops</span>);
      setInitialized(true);
    }
  }, [dashLayoutContext, initialized]); // TODO: it will rerender more then needed

  // TODO: components must be defined as part of GenericPageLayout
  const Component1 = getComponentByType(VISUALIZATION1.type);
  const Component2 = getComponentByType(VISUALIZATION3.type);
  const Component3 = getComponentByType(VISUALIZATION2.type);

  // Merge all parameters from segment, query & custom
  // `organization` -> `organization_id`, `asOfDate` -> `date_id`...  because it is used in endpoint query param.
  // TODO: make all places same names - so no conversion needed
  const queryParams1 = {
    type: "Race",
    ...queryParams,
  };

  const queryParams2 = {
    type: "Gender",
    ...queryParams,
  };

  const queryParams3 = {
    type: "Age",
    ...queryParams,
  };

  const params = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
    },
    queryParams1
  );

  const params2 = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
    },
    queryParams2
  );

  const params3 = Object.assign(
    {},
    segmentParams,
    {
      organization_id: segmentParams.organization,
      date_id: segmentParams.asOfDate,
      date_unit: segmentParams.timeSpanUnit,
    },
    queryParams3
  );

  return (
    <div className={classes.PersonPeerRoot}>
      <div className={classes.Content}>
        <Component1
          {...{
            endpoint: ENDPOINT1,
            visualization: VISUALIZATION1,
            params: params,
          }}
        />
      </div>
      <div className={classes.Content}>
        <Component3
          {...{
            endpoint: ENDPOINT2,
            visualization: VISUALIZATION2,
            params: params2,
          }}
        />
      </div>
      <div className={classes.Content}>
        <Component2
          {...{
            endpoint: ENDPOINT3,
            visualization: VISUALIZATION3,
            params: params3,
          }}
        />
      </div>
    </div>
  );
};

export default withParams(PostStopInteraction);
