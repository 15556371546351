const statusPayload = {
  current_branch: process.env.REACT_APP_CURRENT_BRANCH || "unknown",
  current_commit: process.env.REACT_APP_CURRENT_COMMIT || "unknown",
  current_commit_message:
    process.env.REACT_APP_CURRENT_COMMIT_MESSAGE || "unknown",
  build_time: process.env.REACT_APP_BUILD_TIME || "unknown",
  started: process.env.START_TIME || "unknown",
  version: process.env.REACT_APP_VERSION || "unknown",
};

export const getStatus = () => {
  return JSON.stringify(statusPayload);
};
