import React from "react";
import { LineChart, XAxis, YAxis, CartesianGrid, Line } from "recharts";

function TestChart({ title, endpoint, visualization }) {
  console.log("TestChart: ", title, endpoint, visualization);
  const data = [
    { name: "Page A", uv: 400, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 400, pv: 1400, amt: 1400 },
  ];
  return (
    <>
      <h2>{title}</h2>
      <LineChart width={400} height={400} data={data}>
        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="name" />
        <YAxis />
      </LineChart>
    </>
  );
}

export default TestChart;
