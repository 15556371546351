export const createLabelAndCalculateDomainMargin = (data) => {
  let max = 0;
  data.forEach((item) => {
    const persons = parseInt(item.n_persons);
    if (persons > max) max = persons;
    item.label = item.organization_name.substr(0, 10);
    item.n_persons = persons;
  });

  return {
    data,
    domainMargin: Math.round(max * 0.1),
  };
};
