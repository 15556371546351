import { linksPermissions, statsLinksPermissions } from './PermisionsMeta';
import { links } from './LinksMarkup';
import { statsLinks } from './LinksMarkup';

export const resolve = (permissions) => {
    const allowedLinks = [];
    for(let linkName in linksPermissions){
        if(linksPermissions[linkName][0] === 'everybody' ||
         permissions.some(permission => linksPermissions[linkName].includes(permission))) {
            allowedLinks.push(links[linkName]);
        }
    }
    return allowedLinks;
};

export const resolveForStats = (permissions) => {
    const allowedLinks = [];
    for(let linkName in statsLinksPermissions){
        if(permissions.some(permission => statsLinksPermissions[linkName].includes(permission))) {
            allowedLinks.push(statsLinks[linkName]);
        }
    }

    const sorted = [
        allowedLinks.find(x => x.key === 'accessStatsReports'),
        allowedLinks.find(x => x.key === 'accessStatsDesigner'),
        allowedLinks.find(x => x.key === 'accessStatsAdmin'),
    ]
    return sorted; 
};