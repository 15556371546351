import axios from "axios";
import config from "../config.json";

const BASE_URL = config.statsApiUrl;
const TOKEN_KEY = "__storejs_bdm_token";

export const getEventDetails = async (asOfDate, timeSpanUnit, organization) => {
  let token = localStorage.getItem(TOKEN_KEY);
  const query = config.mock
    ? "?mock=mock"
    : `?organization_id=${organization}&date_id=${asOfDate}&date_unit=${timeSpanUnit}`;
  const response = await axios.get(
    `${BASE_URL}/report/events_totals_by_organization${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
