import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "report/reason_by_age_by_organization",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "By Subject Age",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Category",
        dataIndex: "dimension",
      },
      {
        title: "Total",
        dataIndex: "total_count",
      },
      {
        title: "Moving Violation",
        dataIndex: "moving",
      },
      {
        title: "Peer Moving Violation",
        dataIndex: "moving_peer",
      },
      {
        title: "Nonmoving Violation",
        dataIndex: "non_moving",
      },
      {
        title: "Peer Nonmoving Violation",
        dataIndex: "non_moving_peer",
      },
      {
        title: "Other",
        dataIndex: "other",
      },
      {
        title: "Peer Other",
        dataIndex: "other_peer",
      },
    ],
  },
};
