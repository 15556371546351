import React from "react";
import classes from "./TableBox.module.scss";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TableBox({
  columnDefinitions,
  data,
  loaderText,
  title,
  scrollWidth,
  failedText,
}) {
  const titleContent = failedText ? (
    <h2 className={classes.Failed}>{failedText}</h2>
  ) : (
    <h2>
      <span>{title}</span>
      {loaderText ? (
        <div className={classes.Loader}>
          {loaderText}
          <div className={classes.SpinnerBox}>
            <FontAwesomeIcon
              className="fa-spin"
              icon={["fas", "circle-notch"]}
            />
          </div>
        </div>
      ) : null}
    </h2>
  );

  return (
    <div className={classes.TableBox}>
      {titleContent}
      <Table
        scroll={{ x: scrollWidth }}
        bordered
        columns={columnDefinitions}
        pagination={false}
        dataSource={data}
      />
    </div>
  );
}

export default TableBox;
