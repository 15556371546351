import React, { useEffect, useState } from "react";
import classes from "./GridComponent.module.scss";
import { Table, Input } from "antd";
import { getApi } from "../../services/GenericApiService";
import config from "../../config.json";

const { Search } = Input;

function GridComponent({ endpoint, visualization, params }) {
  const { url, queryParams } = endpoint;
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = [];
    if (config.mock) {
      query.push("mock=mock");
    }
    if (params) {
      Object.keys(queryParams).forEach((key) => {
        if (params[key]) {
          query.push(`${key}=${params[key]}`);
        }
      });
    }
    getApi(`${url}?${query.join("&")}`)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params, url, queryParams]);

  const [search, updateSearch] = useState("");
  const filteredData =
    search.length < 2
      ? data
      : data.filter((d) => {
          return (
            JSON.stringify(d)
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) >= 0
          );
        });

  return (
    <>
      <h2>{visualization.title}</h2>
      <Search
        className={classes.SearchBox}
        placeholder="Search"
        onSearch={updateSearch}
      />
      <div className={classes.TableWrapper}>
        <Table
          dataSource={filteredData.map((d, ix) => {
            d.key = ix;
            return d;
          })}
          {...visualization.params}
        />
      </div>
    </>
  );
}

export default GridComponent;
