import React, { useEffect, useState, useContext } from "react";
import classes from "./Analysis.module.scss";
import { withParams } from "../../components/WithParamsComponent/WithParams";
import { NavLink } from "react-router-dom";
import {
  biasGenderAnalysis,
  biasRaceAnalysis,
  biasAgeAnalysis,
  biasDataTransform,
} from "../../services/DashLayoutService";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayoutContext from "../../layouts/DashboardLayoutComponent/DashboardLayoutContext";
import { MV_STOP_DEMOGRAPHICS_TITLE } from "../../layouts/DashboardLayoutComponent/DashboardLayout";

function Analysis({ segmentParams }) {
  const [raceBiasData, setRaceBiasData] = useState(null);
  const [genderBiasData, setGenderBiasData] = useState(null);
  const [ageBiasData, setAgeBiasData] = useState(null);
  const [fetching, setFetching] = useState(null);
  const [initialized, setInitialized] = useState(null);

  const dashLayoutContext = useContext(DashboardLayoutContext);

  const columns = [
    {
      title: "Category",
      dataIndex: "dimension",
      key: "dimension",
    },
    {
      title: "Count",
      dataIndex: "count_value",
      key: "count_value",
    },
    {
      title: "% of Total",
      dataIndex: "pctValue",
      key: "pctValue",
    },
    {
      title: "Peer %",
      dataIndex: "peerAverageValue",
      key: "peerAverageValue",
    },
    {
      title: "Details",
      dataIndex: "eventLink",
      key: "Details",
      // TODO: We should use global dictionary(ies) for dimensions
      render: (text, record) => {
        let query = "";
        switch (record.dimension_type) {
          case "Age":
            query = `?by_subject_age=${record.dimension_id}`;
            break;
          case "Gender":
            query = `?by_subject_gender=${record.dimension_id}`;
            break;
          case "Race":
            query = `?by_subject_race=${record.dimension_id}`;
            break;
          default:
            break;
        }
        return (
          <NavLink to={text.slug + query}>
            <FontAwesomeIcon icon={["fas", "list"]} />
          </NavLink>
        );
      },
    },
  ];

  useEffect(() => {
    if (!initialized) {
      dashLayoutContext.setPageHeading(MV_STOP_DEMOGRAPHICS_TITLE);
      dashLayoutContext.setBreadCrumb(<span>Motor Vehicle Stops</span>);
      setInitialized(true);
    }
  }, [dashLayoutContext, initialized]);

  useEffect(() => {
    const addEventLink = (data) => {
      data.forEach((item) => {
        item.eventLink = {
          slug: `/event/CAD Motor Vehicle Stops/${segmentParams.asOfDate}/${segmentParams.timeSpanUnit}/${segmentParams.organization}`,
        };
      });

      return data;
    };
    const fetchAnalyses = async () => {
      try {
        setFetching(true);
        const raceAnalysis = await biasRaceAnalysis(
          segmentParams.organization,
          segmentParams.asOfDate,
          segmentParams.timeSpanUnit
        );
        const genderAnalysis = await biasGenderAnalysis(
          segmentParams.organization,
          segmentParams.asOfDate,
          segmentParams.timeSpanUnit
        );
        const ageAnalysis = await biasAgeAnalysis(
          segmentParams.organization,
          segmentParams.asOfDate,
          segmentParams.timeSpanUnit
        );
        setFetching(false);
        setRaceBiasData(biasDataTransform(addEventLink(raceAnalysis)));
        setGenderBiasData(biasDataTransform(addEventLink(genderAnalysis)));
        setAgeBiasData(biasDataTransform(addEventLink(ageAnalysis)));
      } catch (error) {
        // TODO: set toast notification
        console.log("Error initializing dashboard page", error);
      }
    };
    if (!fetching) {
      fetchAnalyses();
    }
    // dont want to rerender on fetching change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    segmentParams.organization,
    segmentParams.asOfDate,
    segmentParams.timeSpanUnit,
  ]);

  return (
    <div className={classes.Analysis}>
      {raceBiasData ? (
        <div className={classes.TableBox}>
          <h2>Race Bias Analysis</h2>
          <Table
            pagination={false}
            dataSource={raceBiasData}
            columns={columns}
          />
        </div>
      ) : null}

      {genderBiasData ? (
        <div className={classes.TableBox}>
          <h2>Gender Bias Analysis</h2>
          <Table
            pagination={false}
            dataSource={genderBiasData}
            columns={columns}
          />
        </div>
      ) : null}

      {ageBiasData ? (
        <div className={classes.TableBox}>
          <h2>Age Bias Analysis</h2>
          <Table
            pagination={false}
            dataSource={ageBiasData}
            columns={columns}
          />
        </div>
      ) : null}
    </div>
  );
}

export default withParams(Analysis);
