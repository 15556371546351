import React from 'react';
import classes from './UserCard.module.scss';

function UserCard({user}) {

    const getInitials = (user) => {
        return `${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`
    };

    return (
        <div className={classes.UserCard}>
            <div className={classes.UserCircle}>
                <label>{getInitials(user)}</label>
            </div>
            <h3 className={classes.Name}>{user.fullName}</h3>
            <h2 className={classes.Rank}>{user.rank.name}</h2>
            <h4 className={classes.Department}>{user.agency.name}</h4>
        </div>
    );
}

export default UserCard;