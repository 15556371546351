// TODO: should be taken from BE

import { VISUALIZATION_TYPE_GRID } from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "/report/post_interaction_compare_interaction_by_organization",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    type: true,
    dimension: true,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "Compare Outcome By Peers",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 10,
    columns: [
      {
        title: "Organization Name",
        dataIndex: "organization_name",
      },
      {
        title: "Total",
        dataIndex: "org_dim_total",
      },
      {
        title: "Frisk Total",
        dataIndex: "org_frisk",
      },
      {
        title: "Peer Frisk",
        dataIndex: "org_frisk_residual",
      },
      {
        title: "Consent Request Total",
        dataIndex: "org_consent",
      },
      {
        title: "Peer Consent Request",
        dataIndex: "org_consent_residual",
      },
      {
        title: "PC Search",
        dataIndex: "org_pc",
      },
      {
        title: "Peer PC Search",
        dataIndex: "org_pc_residual",
      },
      {
        title: "Other Search",
        dataIndex: "org_search",
      },
      {
        title: "Peer Other Search",
        dataIndex: "org_search_residual",
      },
      {
        title: "K9 Usage",
        dataIndex: "org_k9",
      },
      {
        title: "Peer K9 Usage",
        dataIndex: "org_k9_residual",
      },
    ],
  },
};
