import React, { useEffect, useState } from 'react';
import classes from './Navigation.module.scss';
import { resolve } from './NavigationResolver';

function Navigation({permissions}) {

    const [links, setLinks] = useState([]);

    useEffect(() => {
        setLinks(resolve(permissions));
    }, [permissions])

    return (
        <ul className={classes.Navigation}>
            {links}
        </ul>
    );
}

export default Navigation;