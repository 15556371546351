// TODO: should be taken from BE

import {
  VISUALIZATION_TYPE_GRID,
  VISUALIZATION_TYPE_SCATTERPLOT,
} from "../../base-visualization/visualization-protocol";

export const ENDPOINT = {
  url: "/report/stops_compare_by_officer",
  queryParams: {
    organization_id: true,
    date_id: false,
    date_unit: false,
    type: true,
    dimension: true,
    mock: false,
  },
  shape: {},
};

export const VISUALIZATION = {
  title: "Trooper Stops",
  type: VISUALIZATION_TYPE_GRID,
  params: {
    bordered: true,
    pagination: 50,
    columns: [
      {
        title: "Trooper Name",
        dataIndex: "officer_full_name",
      },
      {
        title: "Percent of Total",
        dataIndex: "pct_percent_str",
      },
      {
        title: "Upper Bound",
        dataIndex: "upper_boundary",
      },
      {
        title: "Lower Bound",
        dataIndex: "lower_boundary",
      },
    ],
  },
};
