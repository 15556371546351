import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import classes from "./Navigation.module.scss";
import config from "../../config.json";

const LEGACY_ROOT = config.bmsUrl;

export const links = {
  home: (
    <li key="home">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}`}>
        <FontAwesomeIcon className={classes.Icon} icon={["fas", "home"]} />
        <span>Home</span>
      </a>
    </li>
  ),
  people: (
    <li key="people">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/people`}>
        <FontAwesomeIcon
          className={classes.Icon}
          icon={["fas", "user-friends"]}
        />
        <span>People</span>
      </a>
    </li>
  ),
  "my profile": (
    <li key="myprofile">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/profile`}>
        <FontAwesomeIcon
          className={classes.Icon}
          icon={["fas", "user-friends"]}
        />
        <span>My Profile</span>
      </a>
    </li>
  ),
  analytics: (
    <li key="analytics">
      <NavLink className={`${classes.NavigationLink} ${classes.Active}`} to="/">
        <FontAwesomeIcon
          className={classes.Icon}
          icon={["fas", "chart-line"]}
        />
        <span>Analytics</span>
      </NavLink>
    </li>
  ),
  reports: (
    <li key="reports">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/reports`}>
        <FontAwesomeIcon className={classes.Icon} icon={["fas", "file-alt"]} />
        <span>Reports</span>
      </a>
    </li>
  ),
  training: (
    <li key="training">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/training`}>
        <FontAwesomeIcon
          className={classes.Icon}
          icon={["fas", "certificate"]}
        />
        <span>Training</span>
      </a>
    </li>
  ),
  "internal affairs": (
    <li key="internalaffairs">
      <a
        className={classes.NavigationLink}
        href={`${LEGACY_ROOT}/internal-affairs`}
      >
        <FontAwesomeIcon className={classes.Icon} icon={["fas", "user-tag"]} />
        <span>Internal Affairs</span>
      </a>
    </li>
  ),
  admin: (
    <li key="admin">
      <a
        className={classes.NavigationLink}
        href={`${LEGACY_ROOT}/administrator/agencies`}
      >
        <FontAwesomeIcon className={classes.Icon} icon={["fas", "key"]} />
        <span>Admin</span>
      </a>
    </li>
  ),
  settings: (
    <li key="settings">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/settings`}>
        <FontAwesomeIcon className={classes.Icon} icon={["fas", "cog"]} />
        <span>Settings</span>
      </a>
    </li>
  ),
  logout: (
    <li key="logout">
      <a className={classes.NavigationLink} href={`${LEGACY_ROOT}/logout`}>
        <FontAwesomeIcon
          className={classes.Icon}
          icon={["fas", "sign-out-alt"]}
        />
        <span>Log Out</span>
      </a>
    </li>
  ),
};

export const statsLinks = {
  accessStatsDesigner: (
    <li key="accessStatsDesigner">
      <NavLink to="/report-builder" className={classes.StatsLink}>
        <FontAwesomeIcon
          className={classes.StatsIcon}
          icon={["fas", "pencil-ruler"]}
        />
        <span>Report Designer</span>
      </NavLink>
    </li>
  ),
  accessStatsAdmin: (
    <li key="accessStatsAdmin">
      <NavLink to="/report-builder-admin" className={classes.StatsLink}>
        <FontAwesomeIcon
          className={classes.StatsIcon}
          icon={["fas", "stamp"]}
        />
        <span>Designer Admin</span>
      </NavLink>
    </li>
  ),
  accessStatsReports: (
    <li key="accessStatsReports">
      <NavLink to="/canned-reports" className={classes.StatsLink}>
        <FontAwesomeIcon
          className={classes.StatsIcon}
          icon={["fas", "layer-group"]}
        />
        <span>Canned Reports</span>
      </NavLink>
    </li>
  ),
};
