import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./EventLink.module.scss";

function EventLink({ eventData, asOfDate, timeSpanUnit, organization }) {
  return (
    <div>
      {eventData ? (
        <NavLink
          to={`/event/${eventData.event_type_name}/${asOfDate}/${timeSpanUnit}/${organization}`}
          className={classes.EventLink}
        >
          <div className={classes.Info}>
            <div className={classes.Type}>{eventData.event_type_name}</div>
            <div className={classes.Total}>{eventData.total_events}</div>
          </div>
        </NavLink>
      ) : null}
    </div>
  );
}

export default EventLink;
